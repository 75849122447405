exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-exhibition-js": () => import("./../../../src/templates/exhibition.js" /* webpackChunkName: "component---src-templates-exhibition-js" */)
}

